import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { registerGuard } from "./Guard";
import store from "../store/index";
import useDisableElement from "@/composables/disableElements";

const cookie: any = document.cookie;
const tokenAdb2c: any = localStorage.getItem("tokenAdb2c");
//
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    // redirect: cookie.includes("changed_credentials=true")
    //   ? "/bacheca-public"
    //   : "/sign-in",
    // redirect: cookie.includes("changed_credentials=true")
    //   ? "/bacheca-public"
    //   : "/sign-in",
    // redirect: "/sign-in",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/",
        name: "Loader",
        component: () => import("@/views/Loader.vue"),
      },
      {
        path: "/logout",
        name: "Logout",
        component: () => import("@/views/Logout.vue"),
      },
      {
        path: "/logout-post",
        name: "Logout-post",
        component: () => import("@/views/Loader.vue"),
      },
      {
        path: "/verifiche-anomalie",
        name: "verifiche-anomalie",
        component: () =>
          import("@/views/verifiche-anomalie/VerificheAnomalieList.vue"),
      },
      {
        path: "/bacheca",
        name: "Bacheca",
        component: () => import("@/views/Bacheca.vue"),
      },
      {
        path: "/bacheca-public",
        name: "BachecaPublic",
        component: () => import("@/views/BachecaPublic.vue"),
      },
      /* {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      }, */
      {
        path: "/societa",
        name: "societa",
        component: () => import("@/views/societa/SocietaList.vue"),
      },
      {
        path: "/enti-aggregati",
        name: "enti-aggregati",
        component: () => import("@/views/enti-aggregati/EntiAggregatiList.vue"),
      },
      {
        path: "/enti-aggregati/add-ente",
        name: "add-ente",
        component: () => import("@/views/enti-aggregati/AddEnte.vue"),
      },
      {
        path: "/enti-aggregati/dettaglio-ente",
        name: "dettaglio-ente",
        component: () =>
          import(
            "@/views/enti-aggregati/dettaglio-ente/EnteAggregatoDetail.vue"
          ),
        children: [
          {
            path: "anagrafica-ente/:id",
            name: "anagrafica-ente",
            component: () =>
              import(
                "@/views/enti-aggregati/dettaglio-ente/AnagraficaEnte.vue"
              ),
          },
          {
            path: "atto-ente/:id",
            name: "atto-ente",
            component: () =>
              import("@/views/enti-aggregati/dettaglio-ente/AttoEnte.vue"),
          },
          {
            path: "referente-ente/:id",
            name: "referente-ente",
            component: () =>
              import(
                "@/views/enti-aggregati/dettaglio-ente/ReferenteResponsabileEnteAggregato.vue"
              ),
          },
          {
            path: "consiglio-ente/:id",
            name: "consiglio-ente",
            component: () =>
              import("@/views/enti-aggregati/dettaglio-ente/ConsiglioEnte.vue"),
          },
          {
            path: "impianti-ente/:id",
            name: "impianti-ente",
            component: () =>
              import(
                "@/views/enti-aggregati/dettaglio-ente/impianti-ente/ImpiantiEnte.vue"
              ),
          },
          {
            path: "impianti-ente/assegna-impianto/:id",
            name: "ente-assegna-impianto",
            component: () =>
              import(
                "@/views/enti-aggregati/dettaglio-ente/impianti-ente/AssegnaImpiantoEnte.vue"
              ),
          },
          {
            path: "impianti-ente/dettaglio-impianto/:id/:id_impianto",
            name: "dettaglio-impianto-ente",
            props: true,
            component: () =>
              import(
                "@/views/enti-aggregati/dettaglio-ente/impianti-ente/DetailImpiantoEnte.vue"
              ),
          },
          {
            path: "impianti-ente/omologazioni-impianto/:id/:id_impianto",
            props: true,
            name: "lista-omologazioni-impianto-ente",
            component: () =>
              import(
                "@/views/enti-aggregati/dettaglio-ente/impianti-ente/ListOmologEnte.vue"
              ),
          },
          {
            path: "impianti-ente/omologazioni-impianto/dettaglio-omologazione/:id/:id_impianto/:id_omo",
            name: "dettaglio-omologazione-ente",
            component: () =>
              import(
                "@/views/enti-aggregati/dettaglio-ente/impianti-ente/DetailOmologEnte.vue"
              ),
          },
          {
            path: "impianti-ente/omologazioni-impianto/nuova-omologazione/:id/:id_impianto",
            name: "nuova-omologazione-ente",
            component: () =>
              import(
                "@/views/enti-aggregati/dettaglio-ente/impianti-ente/AddOmologEnte.vue"
              ),
          },
          {
            path: "affiliazione-ente/:id",
            name: "affiliazione-ente",
            component: () =>
              import(
                "@/views/enti-aggregati/dettaglio-ente/AffiliazioneEnte.vue"
              ),
          },

          {
            path: "ente-tesserati/:id",
            name: "ente-tesserati",
            component: () =>
              import(
                "@/views/enti-aggregati/dettaglio-ente/TesseratiUnaEnte.vue"
              ),
          },
          // {
          //   path: "rinnovi-ente/:id",
          //   name: "rinnovi-ente",
          //   component: () =>
          //     import("@/views/enti-aggregati/dettaglio-ente/RinnoviEnte.vue"),
          // },

          {
            path: "documenti-ente/:id",
            name: "documenti-ente",
            component: () =>
              import("@/views/enti-aggregati/dettaglio-ente/DocumentiEnte.vue"),
          },
          {
            path: "economato-ente/:id",
            name: "economato-ente",
            component: () =>
              import("@/views/enti-aggregati/dettaglio-ente/EconomatoEnte.vue"),
          },
          {
            path: "sanzioni-ente/:id",
            name: "sanzioni-ente",
            component: () =>
              import("@/views/enti-aggregati/dettaglio-ente/SanzioniEnte.vue"),
          },
          {
            path: "privacy-ente/:id",
            name: "privacy-ente",
            component: () =>
              import("@/views/enti-aggregati/dettaglio-ente/PrivacyEnte.vue"),
          },
          // {
          //   path: "trasferimenti-ente/:id",
          //   name: "trasferimenti-ente",
          //   component: () =>
          //     import(
          //       "@/views/enti-aggregati/dettaglio-ente/TrasferimentiUnaEnte.vue"
          //     ),
          // },
          // NON UTLIZZATO
          // {
          //   path: "scuole-tennis-societa/:id",
          //   name: "scuole-tennis-societa",
          //   component: () =>
          //     import(
          //       "@/views/enti-aggregati/dettaglio-ente/ScuoleTennisSocieta.vue"
          //     ),
          // },
          // {
          //   path: "richieste-societa/:id",
          //   name: "richieste-societa",
          //   component: () =>
          //     import(
          //       "@/views/enti-aggregati/dettaglio-ente/RichiesteSocieta.vue"
          //     ),
          // },
          // {
          //   path: "tecnici-ente/:id",
          //   name: "tecnici-ente",
          //   component: () =>
          //     import("@/views/enti-aggregati/dettaglio-ente/TecniciEnte.vue"),
          // },
        ],
      },
      {
        path: "/societa/add-societa",
        name: "add-societa",
        component: () => import("@/views/societa/AddSocieta.vue"),
      },
      {
        path: "/pagamento-effettuato",
        name: "pagamento-effettuato",
        component: () => import("@/views/pagamenti/PagamentoOK.vue"),
      },
      {
        path: "/pagamento-fallito",
        name: "pagamento-fallito",
        component: () => import("@/views/pagamenti/PagamentoKO.vue"),
      },
      {
        path: "/affiliazioni-secondarie",
        name: "affiliazioni-secondarie",
        component: () =>
          import("@/views/affiliazioni-secondarie/AffiliazioniSecondarie.vue"),
      },
      {
        path: "/societa/dettaglio-societa",
        name: "dettaglio-societa",
        component: () =>
          import("@/views/societa/dettaglio-societa/SocietaDetail.vue"),
        children: [
          {
            path: "anagrafica-societa/:id",
            name: "anagrafica-societa",
            component: () =>
              import("@/views/societa/dettaglio-societa/AnagraficaSocieta.vue"),
          },
          {
            path: "atto-societa/:id",
            name: "atto-societa",
            component: () =>
              import("@/views/societa/dettaglio-societa/AttoSocieta.vue"),
          },
          {
            path: "consiglio-societa/:id",
            name: "consiglio-societa",
            component: () =>
              import("@/views/societa/dettaglio-societa/ConsiglioSocieta.vue"),
          },
          {
            path: "impianti-societa/:id",
            name: "impianti-societa",
            component: () =>
              import(
                "@/views/societa/dettaglio-societa/impianti-societa/ImpiantiSocieta.vue"
              ),
          },
          {
            path: "impianti-societa/assegna-impianto/:id",
            name: "assegna-impianto",
            component: () =>
              import(
                "@/views/societa/dettaglio-societa/impianti-societa/AssegnaImpiantoSocieta.vue"
              ),
          },
          {
            path: "impianti-societa/dettaglio-impianto/:id/:id_impianto",
            name: "dettaglio-impianto-societa",
            props: true,
            component: () =>
              import(
                "@/views/societa/dettaglio-societa/impianti-societa/DetailImpiantoSoc.vue"
              ),
          },
          {
            path: "impianti-societa/omologazioni-impianto/:id/:id_impianto",
            props: true,
            name: "lista-omologazioni-impianto-societa",
            component: () =>
              import(
                "@/views/societa/dettaglio-societa/impianti-societa/ListOmologSoc.vue"
              ),
          },
          {
            path: "impianti-societa/omologazioni-impianto/dettaglio-omologazione/:id/:id_impianto/:id_omo",
            name: "dettaglio-omologazione-soc",
            component: () =>
              import(
                "@/views/societa/dettaglio-societa/impianti-societa/DetailOmologSoc.vue"
              ),
          },
          {
            path: "impianti-societa/omologazioni-impianto/nuova-omologazione/:id/:id_impianto",
            name: "nuova-omologazione-soc",
            component: () =>
              import(
                "@/views/societa/dettaglio-societa/impianti-societa/AddOmologSoc.vue"
              ),
          },
          {
            path: "affiliazione-societa/:id",
            name: "affiliazione-societa",
            component: () =>
              import(
                "@/views/societa/dettaglio-societa/AffiliazioneSocieta.vue"
              ),
          },
          {
            path: "tecnici-societa/:id",
            name: "tecnici-societa",
            component: () =>
              import("@/views/societa/dettaglio-societa/TecniciSocieta.vue"),
          },
          {
            path: "societa-tesserati/:id",
            name: "societa-tesserati",
            component: () =>
              import(
                "@/views/societa/dettaglio-societa/TesseratiUnaSocieta.vue"
              ),
          },
          {
            path: "rinnovi-societa/:id",
            name: "rinnovi-societa",
            component: () =>
              import("@/views/societa/dettaglio-societa/RinnoviSocieta.vue"),
          },
          {
            path: "scuole-tennis-societa/:id",
            name: "scuole-tennis-societa",
            component: () =>
              import(
                "@/views/societa/dettaglio-societa/ScuoleTennisSocieta.vue"
              ),
          },
          {
            path: "documenti-societa/:id",
            name: "documenti-societa",
            component: () =>
              import("@/views/societa/dettaglio-societa/DocumentiSocieta.vue"),
          },
          {
            path: "economato-societa/:id",
            name: "economato-societa",
            component: () =>
              import("@/views/societa/dettaglio-societa/EconomatoSocieta.vue"),
          },
          {
            path: "sanzioni-societa/:id",
            name: "sanzioni-societa",
            component: () =>
              import("@/views/societa/dettaglio-societa/SanzioniSocieta.vue"),
          },
          {
            path: "richieste-societa/:id",
            name: "richieste-societa",
            component: () =>
              import("@/views/societa/dettaglio-societa/RichiesteSocieta.vue"),
          },
          {
            path: "privacy-societa/:id",
            name: "privacy-societa",
            component: () =>
              import("@/views/societa/dettaglio-societa/PrivacySocieta.vue"),
          },
          {
            path: "trasferimenti-societa/:id",
            name: "trasferimenti-societa",
            component: () =>
              import(
                "@/views/societa/dettaglio-societa/TrasferimentiSocieta.vue"
              ),
          },
        ],
      },
      {
        path: "/tesserati/tesserati-federali",
        name: "tesserati-federali",
        component: () => import("@/views/tesserati/TesseratiFederaliList.vue"),
      },
      {
        path: "/tesserati/tesserati-societa",
        name: "tesserati-societa",
        component: () => import("@/views/tesserati/TesseratiSocietaList.vue"),
      },
      {
        path: "/tesserati/richieste-trasferimento",
        name: "richieste-trasferimento",
        component: () => import("@/views/tesserati/RichiesteTrasferimento.vue"),
      },
      {
        path: "/tesserati/trasferimenti",
        name: "trasferimenti",
        component: () => import("@/views/tesserati/TrasferimentiList.vue"),
      },
      {
        path: "/tesserati/vivaio",
        name: "vivaio",
        component: () => import("@/views/tesserati/Vivaio.vue"),
      },
      {
        path: "/tesserati/vivaio-coninet",
        name: "vivaio-coninet",
        component: () => import("@/views/tesserati/VivaioConinet.vue"),
      },
      {
        path: "/tesserati/vivaio",
        name: "vivaio",
        component: () => import("@/views/tesserati/Vivaio.vue"),
      },
      {
        path: "/albo",
        name: "albo",
        component: () => import("@/views/albo/AlboList.vue"),
      },
      {
        path: "/persone",
        name: "persone",
        component: () => import("@/views/persone/PersoneList.vue"),
      },
      {
        path: "/persone/add-persona",
        name: "add-persona",
        component: () => import("@/views/persone/AddPersona.vue"),
      },
      {
        path: "/persone/dettaglio-persona/",
        name: "dettaglio-persona",
        props: {
          default: true,
          // fromPersone: (route) => ({ fromPersone: route.query.fromPersone }),
        },
        component: () =>
          import("@/views/persone/dettaglio-persona/PersonaDetail.vue"),
        children: [
          {
            path: "anagrafica-persona/:id",
            name: "anagrafica-persona",
            component: () =>
              import("@/views/persone/dettaglio-persona/AnagraficaPersona.vue"),
            props: true,
          },
          {
            path: "documenti-persona/:id",
            name: "documenti-persona",
            component: () =>
              import("@/views/persone/dettaglio-persona/DocumentiPersona.vue"),
            props: true,
          },
          {
            path: "storico-persona/:id",
            name: "storico-persona",
            component: () =>
              import("@/views/persone/dettaglio-persona/StoricoPersona.vue"),
            props: true,
          },
          {
            path: "formazione-persona/:id",
            name: "formazione-persona",
            component: () =>
              import("@/views/persone/dettaglio-persona/FormazionePersona.vue"),
            props: true,
          },
          {
            path: "classifica-persona/:id",
            name: "classifica-persona",
            component: () =>
              import("@/views/persone/dettaglio-persona/ClassificaPersona.vue"),
            props: true,
          },
          {
            path: "privacy-persona/:id",
            name: "privacy-persona",
            component: () =>
              import("@/views/persone/dettaglio-persona/PrivacyPersona.vue"),
            props: true,
          },
          {
            path: "sanzioni-persona/:id",
            name: "sanzioni-persona",
            component: () =>
              import("@/views/persone/dettaglio-persona/SanzioniPersona.vue"),
            props: true,
          },
          {
            path: "verifica-tessera/:id",
            name: "Verifica-Tessera-persona",
            component: () => import("@/views/QrCodePagina.vue"),
            meta: {
              noAuth: true,
            },
          },
        ],
      },
      {
        path: "/omologazioni",
        name: "omologazioni",
        component: () => import("@/views/omologazioni/Omologazioni.vue"),
        children: [
          {
            path: "/omologazioni/richieste",
            name: "omologazioni-richieste",
            component: () =>
              import("@/views/omologazioni/OmologazioniRichieste.vue"),
          },
          {
            path: "/omologazioni/prese-in-carico",
            name: "omologazioni-prese-in-carico",
            component: () =>
              import("@/views/omologazioni/OmologazioniPreseInCarico.vue"),
          },
          {
            path: "/omologazioni/in-attesa",
            name: "omologazioni-in-attesa",
            component: () =>
              import("@/views/omologazioni/OmologazioniInAttesa.vue"),
          },
          {
            path: "/omologazioni/esito",
            name: "omologazioni-esito",
            component: () =>
              import("@/views/omologazioni/OmologazioniEsito.vue"),
          },
          {
            path: "/omologazioni/approvate",
            name: "omologazioni-approvate",
            component: () =>
              import("@/views/omologazioni/OmologazioniApprovate.vue"),
          },
          {
            path: "/omologazioni/rifiutate",
            name: "omologazioni-rifiutate",
            component: () =>
              import("@/views/omologazioni/OmologazioniRifiutate.vue"),
          },
        ],
      },
      {
        path: "/omologazioni/dettaglio-omologazione/:id/:id_impianto/:id_omo/:id_stato",
        props: true,
        name: "omologazioni-dettaglio",
        component: () =>
          import("@/views/omologazioni/OmologazioniDettaglio.vue"),
      },
      {
        path: "/impianti",
        name: "impianti",
        component: () => import("@/views/impianti/ImpiantiList.vue"),
      },
      {
        path: "/impianti/add-impianto",
        name: "add-impianto",
        component: () => import("@/views/impianti/AddImpianto.vue"),
      },
      {
        path: "/impianti/dettaglio-impianto/:id_impianto",
        name: "dettaglio-impianto",
        component: () => import("@/views/impianti/DetailImpianto.vue"),
      },
      {
        path: "/impianti/omologazioni-impianto/:id_impianto",
        props: true,
        name: "lista-omologazioni-impianto",
        component: () => import("@/views/impianti/ListOmologImpianto.vue"),
      },
      {
        path: "/impianti/omologazioni-impianto/dettaglio-omologazione/:id/:id_omo",
        props: true,
        name: "dettaglio-omologazione-impianto",
        component: () => import("@/views/impianti/DetailOmologImp.vue"),
      },
      {
        path: "/impianti/omologazioni-impianto/nuova-omologazione/:id_impianto",
        name: "nuova-omologazione-impianto",
        component: () => import("@/views/impianti/AddOmologImp.vue"),
      },
      {
        path: "/area-gestionale/economato/",
        name: "economato",
        component: () =>
          import("@/views/area-gestionale/economato/Economato.vue"),
        children: [
          {
            path: "sintesi-economato",
            name: "sintesi-economato",
            component: () =>
              import("@/views/area-gestionale/economato/SintesiEconomato.vue"),
          },
          {
            path: "affiliazioni-economato",
            name: "affiliazioni-economato",
            component: () =>
              import(
                "@/views/area-gestionale/economato/AffiliazioniEconomato.vue"
              ),
          },
          {
            path: "tesseramenti-economato",
            name: "tesseramenti-economato",
            component: () =>
              import(
                "@/views/area-gestionale/economato/TesseramentiEconomato.vue"
              ),
          },
          {
            path: "pagamenti-economato",
            name: "pagamenti-economato",
            component: () =>
              import(
                "@/views/area-gestionale/economato/PagamentiEconomato.vue"
              ),
          },
          {
            path: "movimenti-economato",
            name: "movimenti-economato",
            component: () =>
              import(
                "@/views/area-gestionale/economato/MovimentiEconomato.vue"
              ),
          },
          {
            path: "sanzioni-economato",
            name: "sanzioni-economato",
            component: () =>
              import("@/views/area-gestionale/economato/SanzioniEconomato.vue"),
          },
          {
            path: "tessere-speciali-economato",
            name: "tessere-speciali-economato",
            component: () =>
              import(
                "@/views/area-gestionale/economato/TessereSpecialiEconomato.vue"
              ),
          },
          {
            path: "tessere-gold-economato",
            name: "tessere-gold-economato",
            component: () =>
              import(
                "@/views/area-gestionale/economato/TessereGoldEconomato.vue"
              ),
          },
          {
            path: "saldo-economato",
            name: "saldo-economato",
            component: () =>
              import("@/views/area-gestionale/economato/SaldoEconomato.vue"),
          },
          {
            path: "compensazioni-economato",
            name: "compensazioni-economato",
            component: () =>
              import(
                "@/views/area-gestionale/economato/CompensazioniEconomato.vue"
              ),
          },
        ],
      },
      {
        path: "/area-gestionale/comitati",
        name: "comitati",
        component: () => import("@/views/area-gestionale/ComitatiList.vue"),
      },
      {
        path: "/area-gestionale/report",
        name: "report",
        component: () => import("@/views/report/Report.vue"),
      },
      {
        path: "/area-gestionale/users/",
        name: "users",
        component: () =>
          import("@/views/area-gestionale/utenze-abilitate/UsersList.vue"),
        children: [
          {
            path: "utenti-societa",
            name: "utenti-societa",
            component: () =>
              import(
                "@/views/area-gestionale/utenze-abilitate/UsersSocieta.vue"
              ),
          },
          {
            path: "utenti-persone",
            name: "utenti-persone",
            component: () =>
              import(
                "@/views/area-gestionale/utenze-abilitate/UsersPersone.vue"
              ),
          },
          {
            path: "utenti-comitati",
            name: "utenti-comitati",
            component: () =>
              import(
                "@/views/area-gestionale/utenze-abilitate/UsersComitati.vue"
              ),
          },
          {
            path: "omologatori",
            name: "omologatori",
            component: () =>
              import(
                "@/views/area-gestionale/utenze-abilitate/UsersOmologatori.vue"
              ),
          },
        ],
      },
      {
        path: "/raas",
        name: "raas",
        component: () => import("@/views/raas/RaasList.vue"),
        children: [
          {
            path: "raas-affiliazioni",
            name: "raas-affiliazioni",
            component: () => import("@/views/raas/AffiliazioniRaas.vue"),
          },
          {
            path: "raas-consigli",
            name: "raas-consigli",
            component: () => import("@/views/raas/ConsigliRaas.vue"),
          },
          {
            path: "raas-tesserati",
            name: "raas-tesserati",
            component: () => import("@/views/raas/TesseratiRaas.vue"),
          },
          {
            path: "raas-documenti",
            name: "raas-documenti",
            component: () => import("@/views/raas/DocumentiRaas.vue"),
          },
          {
            path: "raas-sport",
            name: "raas-sport",
            component: () => import("@/views/raas/SportRaas.vue"),
          },
          {
            path: "raas-didattica",
            name: "raas-didattica",
            component: () => import("@/views/raas/DidatticaRaas.vue"),
          },
          {
            path: "raas-formazione",
            name: "raas-formazione",
            component: () => import("@/views/raas/FormazioneRaas.vue"),
          },
        ],
      },
      {
        path: "/snm/scuole",
        name: "scuole",
        component: () => import("@/views/snm/ScuoleList.vue"),
      },
      // {
      //   path: "/snm/gestione-biglietti",
      //   name: "gestione-richiesta-biglietti",
      //   component: () => import("@/views/snm/RichiestaBigliettiLista.vue"),
      // },
      {
        path: "/snm/gestione-biglietti",
        name: "gestione-richiesta-biglietti",
        component: () => import("@/views/snm/RichiestaBigliettiLista.vue"),
      },
      {
        path: "/snm/attestati",
        name: "attestati",
        component: () => import("@/views/snm/AttestatiCorsiList.vue"),
      },
      {
        path: "/snm/gestione-attestati",
        name: "gestione-attestati",
        component: () => import("@/views/snm/AttestatiQualificheList.vue"),
      },
      {
        path: "/snm/corsi",
        name: "corsi",
        component: () => import("@/views/snm/CorsiList.vue"),
      },
      {
        path: "/snm/corsi/attestati/genera/:id",
        name: "attestati-corsi",
        component: () => import("@/views/snm/GeneraAttestatiList.vue"),
      },
      {
        path: "/snm/corsi/iscritti-corso/:id",
        name: "iscritti-corso",
        component: () => import("@/views/snm/IscrittiCorsoList.vue"),
      },
      {
        path: "/snm/albo-snm",
        name: "albo-snm",
        component: () => import("@/views/snm/AlboSnm.vue"),
      },
      {
        path: "/snm/insegnante/",
        name: "insegnante",
        component: () => import("@/views/snm/ImpersonificaInsegnante.vue"),
        children: [
          {
            path: "bacheca-insegnante/:id_persona",
            name: "bacheca-insegnante-snm",
            component: () => import("@/views/BachecaInsegnante.vue"),
          },
          {
            path: "anagrafica-insegnante/:id_persona",
            name: "anagrafica-insegnante-snm",
            props: true,
            component: () => import("@/views/snm/AnagraficaInsegnante.vue"),
          },
          {
            path: "economato-personale/:id_persona",
            name: "economato-personale-snm",
            props: true,
            component: () => import("@/views/snm/EconomatoPersonale.vue"),
          },
          {
            path: "albo-insegnante/:id_persona",
            name: "albo-insegnante-snm",
            props: true,
            component: () => import("@/views/snm/AlboInsegnante.vue"),
          },
          {
            path: "lista-corsi-iscrizioni/:id_persona",
            name: "lista-corsi-iscrizioni-snm",
            props: true,
            component: () => import("@/views/snm/ListCorsiIscrizioni.vue"),
          },
          {
            path: "formazione-insegnante/:id_persona",
            name: "formazione-insegnante-snm",
            props: true,
            component: () => import("@/views/snm/FormazioneInsegnante.vue"),
          },
          {
            path: "documenti-insegnante/:id_persona",
            name: "documenti-insegnante-snm",
            props: true,
            component: () => import("@/views/snm/DocumentiInsegnante.vue"),
          },
          {
            path: "storico-insegnante/:id_persona",
            name: "storico-insegnante-snm",
            props: true,
            component: () => import("@/views/snm/StoricoInsegnante.vue"),
          },
          {
            path: "classifica-insegnante/:id_persona",
            name: "classifica-insegnante-snm",
            props: true,
            component: () => import("@/views/snm/ClassificaInsegnante.vue"),
          },
          {
            path: "privacy-insegnante/:id_persona",
            name: "privacy-insegnante-snm",
            props: true,
            component: () => import("@/views/snm/PrivacyInsegnante.vue"),
          },
          {
            path: "attestati-insegnante/:id_persona",
            name: "attestati-insegnante-list",
            props: true,
            component: () => import("@/views/snm/AttestatiInsegnantiList.vue"),
          },
        ],
      },
      {
        path: "/sanzioni",
        name: "sanzioni",
        component: () => import("@/views/sanzioni/SanzioniList.vue"),
      },
      {
        path: "/sanzioni/visualizza-procedimento/:id",
        name: "visualizza-procedimento",
        component: () => import("@/views/sanzioni/ViewProcedimento.vue"),
      },
      {
        path: "/ranking",
        name: "ranking",
        component: () => import("@/views/ranking/RankingList.vue"),
      },
      /*   {
        path: "/area-economica/quote",
        name: "quote",
        component: () => import("@/views/area-economica/Quote.vue"),
      },
      {
        path: "/area-economica/movimenti",
        name: "movimenti",
        component: () => import("@/views/area-economica/Movimenti.vue"),
      },
      {
        path: "/area-economica/societa-con-fido",
        name: "societa-con-fido",
        component: () => import("@/views/area-economica/SocietaFido.vue"),
      }, */
      {
        path: "/esportazioni",
        name: "esportazioni",
        component: () => import("@/views/esportazioni/Esportazioni.vue"),
      },
      {
        path: "/importazioni",
        name: "importazioni",
        component: () => import("@/views/importazioni/Importazioni.vue"),
      },
      {
        path: "/importazioni/dettaglio-importazione/:guid",
        name: "dettaglio importazione",
        component: () =>
          import("@/views/importazioni/DettaglioImportazione.vue"),
      },
      {
        path: "/report-economici",
        name: "report-economici",
        component: () => import("@/views/report/ReportEconomici.vue"),
      },
      {
        path: "/bonifica-anagrafica",
        name: "bonifica-anagrafica",
        component: () =>
          import("@/views/bonifica-anagrafica/BonificaAnagrafica.vue"),
      },
      {
        path: "/helpdesk-pretesseramento",
        name: "helpdesk-pretesseramento",
        component: () =>
          import(
            "@/views/helpdesk-pretesseramento/HelpdeskPretesseramento.vue"
          ),
      },
      {
        path: "/gestione-quote",
        name: "gestione-quote",
        component: () => import("@/views/gestione-quote/GestioneQuote.vue"),
      },
      {
        path: "/credenziali-sgat/:id_societa",
        name: "credenziali-sgat",
        component: () => import("@/views/CredenzialiSgat.vue"),
      },
      {
        path: "/report-pretesseramento",
        name: "report-pretesseramento",
        component: () => import("@/views/report/ReportPretesseramento.vue"),
      },
      {
        path: "/moduli",
        name: "moduli",
        component: () => import("@/views/Moduli.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/LayoutNoMenu.vue"),
    children: [
      {
        path: "/vt",
        name: "Verifica-Tessera",
        component: () => import("@/views/QrCodePagina.vue"),
        meta: {
          noAuth: true,
        },
      },
      {
        path: "/snm/accesso-insegnanti/",
        name: "accesso-insegnanti",
        component: () => import("@/views/snm/AccessoInsegnanti.vue"),
        children: [
          {
            path: "bacheca-insegnante/:id_persona",
            name: "bacheca-insegnante",
            component: () => import("@/views/BachecaInsegnante.vue"),
          },
          {
            path: "anagrafica-insegnante/:id_persona",
            name: "anagrafica-insegnante",
            props: true,
            component: () => import("@/views/snm/AnagraficaInsegnante.vue"),
          },
          {
            path: "economato-personale/:id_persona",
            name: "economato-personale",
            props: true,
            component: () => import("@/views/snm/EconomatoPersonale.vue"),
          },
          {
            path: "albo-insegnante/:id_persona",
            name: "albo-insegnante",
            props: true,
            component: () => import("@/views/snm/AlboInsegnante.vue"),
          },
          {
            path: "lista-corsi-iscrizioni/:id_persona",
            name: "lista-corsi-iscrizioni",
            props: true,
            component: () => import("@/views/snm/ListCorsiIscrizioni.vue"),
          },
          {
            path: "formazione-insegnante/:id_persona",
            name: "formazione-insegnante",
            props: true,
            component: () => import("@/views/snm/FormazioneInsegnante.vue"),
          },
          {
            path: "documenti-insegnante/:id_persona",
            name: "documenti-insegnante",
            props: true,
            component: () => import("@/views/snm/DocumentiInsegnante.vue"),
          },
          {
            path: "storico-insegnante/:id_persona",
            name: "storico-insegnante",
            props: true,
            component: () => import("@/views/snm/StoricoInsegnante.vue"),
          },
          {
            path: "classifica-insegnante/:id_persona",
            name: "classifica-insegnante",
            props: true,
            component: () => import("@/views/snm/ClassificaInsegnante.vue"),
          },
          {
            path: "privacy-insegnante/:id_persona",
            name: "privacy-insegnante",
            props: true,
            component: () => import("@/views/snm/PrivacyInsegnante.vue"),
          },
          {
            path: "attestati-insegnante/:id_persona",
            name: "attestati-insegnante-list-insegnante",
            props: true,
            component: () => import("@/views/snm/AttestatiInsegnantiList.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    redirect: "/sign-in",
    component: () => import("@/components/page-layouts/Auth.vue"),
    meta: {
      noAuth: true,
    },
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          noAuth: true,
        },
      },
      {
        path: "/verify-email",
        name: "Verify-Email",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/VerifyEmail.vue"),
        meta: {
          noAuth: true,
        },
      },
      {
        path: "/reset-password",
        name: "reset-password",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/ResetPassword.vue"),
        meta: {
          noAuth: true,
        },
      },
      {
        path: "/recupera-username",
        name: "recupera-username",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/RecuperaUsername.vue"
          ),
        meta: {
          noAuth: true,
        },
      },
      {
        path: "/crea-password",
        name: "crea-password",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/NewPassword.vue"),
        meta: {
          noAuth: true,
        },
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    meta: {
      noAuth: true,
    },
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    meta: {
      noAuth: true,
    },
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/manutenzione",
    name: "Manutenzione",
    meta: {
      noAuth: true,
    },
    component: () => import("@/views/crafted/authentication/Manutenzione.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

registerGuard(router);

// controllo campi locked dopo ogni cambio route
router.afterEach((to, from) => {
  const actualRoute = router.currentRoute.value.name;
  useDisableElement(actualRoute);
  store.commit("setFilteredLimitations", actualRoute);
});

router.onError((error) => {
  // console.log(
  //   "QUESTO PERMETTE DI INTERCETTARE ERRORI NEL CARICAMNTO DEI FILE JS. DA IMPLEMENTARE LA GESTIONE DI QUEST'ERRORE",
  //   error,
  //   error.name,
  //   error.message
  // );
  window.location.reload();
});

export default router;
