const getDefaultState = () => {
  return {
    ricercaDopoSelezioneSelectAnomalie: false,
    rowsToSkipVerificheAnomalie: 0,
    fetchRowsVerificheAnomalie: 25,
    currentPageVerificheAnomalie: 1,
    sortColumnVerificheAnomalie: "soggetto",
    sortOrderVerificheAnomalie: "asc",

    id_procedura_di_verificaVerificheAnomalie: null,
    id_comitatoVerificheAnomalie: null,
    id_societaVerificheAnomalie: null,
    nome_societaVerificheAnomalie: null,
    id_disciplinaVerificheAnomalie: null,
    annoVerificheAnomalie: 2024,
    note_richiestaVerificheAnomalie: null,
    esportazioneVerificheAnomalie: null,

    filtroAnomalieVerificheAnomalie: null,

    id_personaVerificheAnomalie: null,
    nomePersonaVerificheAnomalie: null,
    avvioRicercaVerificheAnomalie: false,
  };
};
const state = getDefaultState();

const getters = {
  tipo_qualificaVerificheAnomalie: (state) =>
    state.tipo_qualificaVerificheAnomalie,
  rowsToSkipVerificheAnomalie: (state) => state.rowsToSkipVerificheAnomalie,
  fetchRowsVerificheAnomalie: (state) => state.fetchRowsVerificheAnomalie,
  currentPageVerificheAnomalie: (state) => state.currentPageVerificheAnomalie,
  sortColumnVerificheAnomalie: (state) => state.sortColumnVerificheAnomalie,
  sortOrderVerificheAnomalie: (state) => state.sortOrderVerificheAnomalie,

  id_procedura_di_verificaVerificheAnomalie: (state) =>
    state.id_procedura_di_verificaVerificheAnomalie,
  id_comitatoVerificheAnomalie: (state) => state.id_comitatoVerificheAnomalie,
  id_societaVerificheAnomalie: (state) => state.id_societaVerificheAnomalie,
  id_disciplinaVerificheAnomalie: (state) =>
    state.id_disciplinaVerificheAnomalie,
  annoVerificheAnomalie: (state) => state.annoVerificheAnomalie,
  note_richiestaVerificheAnomalie: (state) =>
    state.note_richiestaVerificheAnomalie,
  esportazioneVerificheAnomalie: (state) => state.esportazioneVerificheAnomalie,
  filtroAnomalieVerificheAnomalie: (state) =>
    state.filtroAnomalieVerificheAnomalie,
  ricercaDopoSelezioneSelectAnomalie: (state) =>
    state.ricercaDopoSelezioneSelectAnomalie,
  nome_societaVerificheAnomalie: (state) => state.nome_societaVerificheAnomalie,
  nomePersonaVerificheAnomalie: (state) => state.nomePersonaVerificheAnomalie,
  id_personaVerificheAnomalie: (state) => state.id_personaVerificheAnomalie,
  avvioRicercaVerificheAnomalie: (state) => state.avvioRicercaVerificheAnomalie,
};

// const actions = {};
const mutations = {
  setFilterVerificheAnomalie: (state, { nome_variabile, valore_variabile }) => {
    state[nome_variabile] = valore_variabile;
  },
  setCurrentPageVerificheAnomalie: (state, value) => {
    console.log(value);
    state.currentPageVerificheAnomalie = value;
    state.rowsToSkipVerificheAnomalie = parseInt(
      (state.currentPageVerificheAnomalie - 1) *
        state.fetchRowsVerificheAnomalie
    );
  },
  resetFiltersVerificheAnomalie: (state) => {
    Object.assign(state, getDefaultState());
  },
  setSortColumnVerificheAnomalie: (state, value) => {
    state.sortColumnVerificheAnomalie = value;
  },
  setSortOrderVerificheAnomalie: (state, value) => {
    state.sortOrderVerificheAnomalie = value;
  },
  setId_personaVerificheAnomalie: (state, value) => {
    state.id_personaVerificheAnomalie = value;
  },
  setNomepersonaVerificheAnomalie: (state, value) => {
    state.nomePersonaVerificheAnomalie = value;
  },
};

export default {
  state,
  getters,
  mutations,
};
